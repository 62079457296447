<template>
  <v-card-text>
    <div>
      <v-icon size="20" class="mr-2" color="primary">mdi-account-group</v-icon>
      <strong>Players:</strong>
      {{ server.players.length }} / {{ server.max_players }}
    </div>
    <div>
      <v-icon size="20" class="mr-2" color="primary">mdi-map</v-icon>
      <strong>Map:</strong>
      {{ server.map }}
    </div>
  </v-card-text>
</template>
<script>
export default {
  props: {
    server: Object,
  }
};
</script>