<template>
  <v-row>
    <v-col cols="12">
      <v-card class="server" v-if="active">
        <v-img height="200px" :src="mapImage(active.map)"></v-img>
        <v-card-title>
          <v-select
            :items="servers"
            item-text="title"
            return-object
            label="Server"
            @change="changeActive"
            :value="active.title"
          ></v-select>
        </v-card-title>
        <card-text :server="active"></card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <data-table :players="active.players" />
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/components/DataTable";
import CardText from "@/components/CardText";

export default {
  computed: {
    ...mapGetters(["servers", "active"]),
  },

  methods: {
    ...mapActions(["setActive", "changeActive", "mapImage"]),
    mapImage(map) {
      const images = require.context("../assets/maps", false, /\.jpg$/);
      let image;
      try {
        image = images("./" + map + ".jpg");
      } catch {
        image = images("./none.jpg");
      }
      return image;
    },

    getActive(server) {
      return JSON.stringify(this.active) == JSON.stringify(server);
    },
  },

  data() {
    return {
      show: false,
    };
  },

  components: {
    DataTable,
    CardText,
  },
};
</script>
