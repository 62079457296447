<template>
  <v-data-table
    :headers="headers"
    :items="players"
    :items-per-page="10"
    sort-by="Frags"
    sort-desc
    class="elevation-1 mt-10"
    mobile-breakpoint="0"
  >
    <template v-slot:[`item.avatar`]="{ item }">
      <img :src="item.avatar" width="50" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    players: Array,
  },
  data() {
    return {
      headers: [
        { text: "Avatar", value: "avatar" },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Score", value: "score" },
        { text: "Time", value: "connected" },
        { text: "Ping", value: "ping" },
      ],
    };
  },
};
</script>