<template>
  <v-row>
    <v-col md="12">
      <carousel-3d 
        :height="320"
        :width="500"
        :display="5"
        :perspective="0"
        v-if="servers.length > 0"
      >
        <slide v-for="(server, i) in servers" :key="server.port" :index="i">
          <v-card @click="changeActive(server)">
            <v-img
              class="white--text align-end d-none-sm"
              height="200px"
              :src="mapImage(server.map)"
            ></v-img>
            <v-card-title>{{ server.title }}</v-card-title>
            <card-text :server="server"></card-text>
          </v-card>
        </slide>
      </carousel-3d>
    </v-col>
    <!-- <v-col v-for="server in servers" :key="server.title" md="4">
      <v-card
        class="server"
        :class="{ active: active.title == server.title }"
        @click="changeActive(server)"
      >
        <v-img
          class="white--text align-end d-none-sm"
          height="200px"
          :src="mapImage(server.map)"
        ></v-img>
        <v-card-title>{{ server.title }}</v-card-title>
        <card-text :server="server"></card-text>
      </v-card>
    </v-col> -->
    <v-col cols="12">
      <data-table v-if="active" :players="active.players"></data-table>
    </v-col>
  </v-row>
</template>

<script>
import DataTable from "@/components/DataTable";
import CardText from "@/components/CardText";
import { mapGetters, mapActions } from "vuex";
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  computed: {
    ...mapGetters(["servers", "active"]),
  },

  methods: {
    ...mapActions(["setActive", "changeActive"]),
    mapImage(map) {
      const images = require.context("../assets/maps", false, /\.jpg$/);
      let image;
      try {
        image = images("./" + map + ".jpg");
      } catch {
        image = images("./none.jpg");
      }
      return image;
    },
  },

  components: {
    DataTable,
    CardText,
    Carousel3d,
    Slide,
  },
};
</script>

<style lang="scss" scoped>
.server {
  transition: 0.3s;
  transform: scale(0.9);
  &.active {
    transform: scale(1.1);
  }
}
</style>
