<template>
  <v-container>
        <carousel class="d-none d-md-flex" />
        <mobile class="d-flex d-md-none" />
  </v-container>
</template>

<script>
import Carousel from "@/components/Carousel";
import Mobile from "@/components/Mobile";
import { mapActions } from "vuex";

export default {
  name: "Home",

  components: {
    Carousel,
    Mobile
  },

  methods: {
    ...mapActions(["getInfo"])
  },

  data: () => ({
  }),
  
  mounted() {
    this.getInfo();
  }
};
</script>
